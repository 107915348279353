import axios from "axios";
import Config from "../config/config";
import { store } from "../store/store";
import { logIn } from "../store/authenticationSlice";
import Swal from "sweetalert2";

const mtrack = axios.create({
  baseURL: Config.getConfig().BASE_URL,
  withCredentials: true,
});

mtrack.interceptors.request.use(
  (config) => {
    const token = store.getState().authentication.token;
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    console.log("error 21", error.response.status);
    
    return Promise.reject(error);
  }
);

mtrack.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // console.log('cok', Cookies.get());
    console.log("error", error.response.status);

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const response = await mtrack.post(
        "/api/user/auth-refresh",
        {},
        {
          headers: {
            refresh_token: localStorage.getItem("refresh-token"),
          },
        }
      );

      const token = response.data.token;
      store.dispatch(logIn(token));
      mtrack.defaults.headers.common["x-access-token"] = token;
      return await mtrack(originalRequest);
    }

    if (error.response.status === 405) {
      const errorMessage = error.response.data || "An unknown error occurred."; // Fallback message
      Swal.fire({
        icon: "error",
        title: "Unauthorized",
        text: errorMessage.split(":")[1],
        confirmButtonText: "Okay",
        customClass: {
          popup: 'z-[9999]', // Assign a high z-index to the modal popup
        },
      });
    }
    return Promise.reject(error);
  }
);

export const commonThenResult = (result) => result.data;

export default mtrack;
