import { createSlice } from "@reduxjs/toolkit";

const initialState = { token: "", user: {}, permission: [], isLoggedIn: false };

const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logIn(state, action) {
      state.isLoggedIn = !!action.payload;
      state.token = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
      state.permission = Array.from({length:140},(_,i) => i);
    },
    logOut(state) {
      state.token = null;
      state.user = {};
      state.permission = [];
    },
  },
});

export const { logIn, logOut, setUser } = authentication.actions;

export default authentication.reducer;
