import React from "react";

const CenterSpinner = ({ message = "Loading..." }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen space-y-4">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid  border-gray-300"></div>
      <p className="text-lg font-medium text-gray-600">{message}</p>
    </div>
  );
};

export default CenterSpinner;