import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Input, Label, Select, Button } from "@windmill/react-ui";
import SearchSelect from "react-select";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { searchEmployees } from "../../../employee/api/employee";
import { subDeptSchema } from "../schema";
import { isEmpty, isFunction } from "lodash";
import { SUB_DEPARTMENT_INITIAL_DATA } from "./constant";
import { getDepartment } from "../../api/organisation";
import appLogger from "../../../../config/appLogger";

const SubDepartmentForm = ({
  handleFormSubmit,
  initialValue,
  formId,
  buttons,
  isDeptHeads = true,
}) => {
  const [employeeHeads, setEmployeeHeads] = useState([]);
  const { companyId } = useSelector((state) => state.companyData);

  const { data: dataEmployee, isLoading } = useQuery({
    queryKey: ["employeeList", companyId],
    queryFn: () => searchEmployees({ companyId }),
    keepPreviousData: true,
  });
  const { data: departmentData, isLoading: departmentLoading } = useQuery({
    queryKey: ["department", companyId],
    queryFn: () => getDepartment({ companyId }),

    onError: (err) => {
      appLogger.log(err);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const options = useMemo(() => {
    if (dataEmployee) {
      return dataEmployee?.rows?.map((employee) => ({
        value: employee.id,
        label: employee.employee.fullName.trim(),
      }));
    }
    return [];
  }, [dataEmployee]);

  const isViewMode = !handleFormSubmit;
  const initialValues = {
    ...SUB_DEPARTMENT_INITIAL_DATA,
    ...initialValue,
  };
  console.log(initialValues);
  useEffect(() => {
    if (initialValue && !isEmpty(initialValue[`subDepartmentHead`])) {
      let employee = [];
      initialValue[`subDepartmentHead`].forEach((element) => {
        employee.push(options.find((employee) => employee.value === element));
      });
      setEmployeeHeads(employee);
    }
  }, [initialValue, options]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={subDeptSchema}
      onSubmit={handleFormSubmit || (() => {})} // no-op function for view mode
      enableReinitialize={true}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
        <form
          id={formId}
          autoComplete="off"
          className="space-y-4"
          onSubmit={isViewMode ? (e) => e.preventDefault() : handleSubmit}
        >
          <div className="mb-10 ml-5">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ml-4 mb-6">
              <h6 className="text-base flex flex-col">Select Department</h6>

              <Field
                as={Select}
                className={`mt-1 mb-6 border border-black  `}
                name="departmentId"
                placeholder="Select"
                disabled={isViewMode}
              >
                <option>SELECT</option>
                {departmentData?.map(({ id, name }) => {
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  );
                })}
              </Field>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1  gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">
                Sub Department Name*
              </span>
              <Label>
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter Sub Department Name`}
                  name={`name`}
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name={`name`}
                  component="div"
                  className="text-red-600"
                />
              </Label>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1  gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">
                Sub Department Code*
              </span>
              <Label>
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter Sub Department Code`}
                  name={"code"}
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name={"code"}
                  component="div"
                  className="text-red-600"
                />
              </Label>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1  gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">Description</span>
              <Label>
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter Sub Department Description`}
                  name="description"
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-600"
                />
              </Label>
            </div>
            {isDeptHeads && (
              <div className="grid md:grid-cols-2 grid-cols-1  items-center gap-4 ml-4 mb-6">
                <span className="text-base flex flex-col">
                  Sub Department Head(s)
                </span>
                <Label>
                  <SearchSelect
                    className="mt-1 mb-6 border"
                    name={`subDepartmentHead`}
                    placeholder={`Select Sub Department Heads`}
                    isMulti
                    value={employeeHeads}
                    onChange={(selectedOptions) => {
                      if (!isViewMode) {
                        setEmployeeHeads(selectedOptions);
                        const selectedIds = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];
                        setFieldValue(`subDepartmentHead`, selectedIds);
                      }
                    }}
                    options={options}
                    isDisabled={isViewMode}
                  />
                </Label>
              </div>
            )}
          </div>
          {isFunction(buttons) && buttons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default SubDepartmentForm;
