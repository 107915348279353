import baseAPIFactory, { commonThenResult } from '../../../api/mtrack';

export const END_POINTS = {
  LOG_IN: () => '/api/user/user-login',
  REGISTER: () => '/api/user/register',
  EDIT_USER_EMPPLOYEE: () => '/api/user/employee-edit',

  CHECK_USER_PRESENT: () => '/api/user/check-user-registered',
};

export const logInApi = (inputData) =>
  baseAPIFactory.post(END_POINTS.LOG_IN(), inputData, {
    withCredentials: true,
    credentials: 'include',
  });

export const registerApi = (inputData) =>
  baseAPIFactory.post(END_POINTS.REGISTER(), inputData);

export const editUserEmployee = (param, inputData) => {
  return baseAPIFactory.patch(END_POINTS.EDIT_USER_EMPPLOYEE(), {
    params: {
      userId: param,
    },
    data: inputData,
  });
};

export const checkUserPresent = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.CHECK_USER_PRESENT(), {
    params: {
      email: inputData.email,
      mobile: inputData.mobile,
    },
  });
  return commonThenResult(response);
};
