import baseAPIFactory, { commonThenResult } from "../../../api/mtrack";

export const END_POINTS = {
  GET_PERMISSIONS: () => "/api/permissions/permissions/",
  GET_USER_ROLE: () => "/api/rolePermissions/role/getUserRoles/",
  CREATE_USER_ROLE: () => "/api/rolePermissions/role/",
  CREATE_EMPLOYEE_ASSIGNED_ROLE: () =>
    "/api/rolePermissions/role/employeesAssignedRole",
  GET_ROLE_PERMISSION: () => "/api/rolePermissions/role-permissions/",
  GET_ROLE_PERMISSION_BY_USER_ROLE: () =>
    "/api/rolePermissions/role-permissions/byUserRole",
  ADD_ROLE_PERMISSION: () => "/api/rolePermissions/role-permissions/add",
  GET_ENTITY_ACCESS: () => "/api/entityAccess/entity-access",
  GET_EMPLOYEE: () =>
    "/api/rolePermissions/role-permission-helper/get-employees",
  CREATE_ONBOARDING_USER_ROLES: () =>
    "/api/rolePermissions/role-permission-helper/create-onbording-user-role",
  GET_EMPLOYEE_ASSIGNED_ROLE: () =>
    "/api/rolePermissions/role/getEmployeesAssignedRole/",
  ADD_EMPLOYEE_ASSIGNED_ROLE: () =>
    "/api/employeeAssignedRole/employee-assignedRole/add",
  REMOVE_EMPLOYEE_ASSIGNED_ROLE: () =>
    "/api/employeeAssignedRole/employee-assignedRole/remove",
};

export const addEmployeeAssignedRole = async (data) => {
  const result = await baseAPIFactory.post(
    END_POINTS.ADD_EMPLOYEE_ASSIGNED_ROLE(),
    data
  );
  return commonThenResult(result);
};

export const addOnBoardingRole = async (data) => {
  const result = await baseAPIFactory.post(
    END_POINTS.CREATE_ONBOARDING_USER_ROLES(),
    data
  );
  return commonThenResult(result);
};

export const getEmployeeAssignedRole = async (inputData) => {
  const result = await baseAPIFactory.get(
    END_POINTS.GET_EMPLOYEE_ASSIGNED_ROLE(),
    {
      params: {
        ...inputData,
      },
    }
  );
  return commonThenResult(result);
};

export const getPermissions = async (inputData) => {
  const result = await baseAPIFactory.get(END_POINTS.GET_PERMISSIONS(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(result);
};
export const getEmployee = async (inputData) => {
  const result = await baseAPIFactory.get(END_POINTS.GET_EMPLOYEE(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(result);
};

export const getEntityAccess = async () => {
  const result = await baseAPIFactory.get(END_POINTS.GET_ENTITY_ACCESS());
  return commonThenResult(result);
};

export const createUserRole = async (inputData) => {
  const result = await baseAPIFactory.post(
    END_POINTS.CREATE_USER_ROLE(),
    inputData
  );
  return commonThenResult(result);
};
export const updateUserRole = async (inputData) => {
  const result = await baseAPIFactory.post(
    END_POINTS.CREATE_EMPLOYEE_ASSIGNED_ROLE(),
    inputData
  );
  return commonThenResult(result);
};

export const getUserRole = async ({ companyId }) => {
  const result = await baseAPIFactory.get(END_POINTS.GET_USER_ROLE(), {
    params: {
      companyId,
    },
  });
  return commonThenResult(result);
};

export const getRolePermission = async ({ companyId }) => {
  const result = await baseAPIFactory.get(END_POINTS.GET_ROLE_PERMISSION(), {
    params: {
      companyId,
    },
  });
  return commonThenResult(result);
};
export const getRolePermissionByUserRole = async (inputData) => {
  const result = await baseAPIFactory.get(
    END_POINTS.GET_ROLE_PERMISSION_BY_USER_ROLE(),
    {
      params: {
        ...inputData,
      },
    }
  );
  return commonThenResult(result);
};

export const addRolePermission = async (data) => {
  const result = await baseAPIFactory.post(
    END_POINTS.ADD_ROLE_PERMISSION(),
    data
  );
  return commonThenResult(result);
};

export const removeEmployeeAssignedRole = async ({
  employeeId,
  userRoleId,
}) => {
  try {
    const result = await baseAPIFactory.delete(
      END_POINTS.REMOVE_EMPLOYEE_ASSIGNED_ROLE(),
      {
        params: {
          employeeId,
          userRoleId,
        },
      }
    );

    return commonThenResult(result);
  } catch (error) {
    console.error("API error:", error);
    throw error; // Ensure error is propagated for mutation to handle
  }
};
