import React from "react";
import DatePicker from "react-datepicker";
import { SearchIcon } from "@heroicons/react/solid";
import { Input } from "@windmill/react-ui";
import { useTranslation } from "react-i18next";
import LocationSelect from "./LocationSelect";
import DepartmentSelect from "./DepartmentSelect";
import RequestTypeSelect from "./RequestType";

function SelectBoxOvertimeApprovals({
  setSelectedLocation,
  setSelectedDepartment,
  setStartDate,
  setEndDate,
  setOvertimeType,
  setTermName,
  setEmployeeCode,
  locations,
  isLoadingLocations,
  departments,
  isLoadingDepartments,
  startDate,
  endDate,
}) {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      {/* Top Row: Date Pickers, Location, Request Type, Department */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        {/* From Date Picker */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t("From Date")}
          </label>
          <input
            type="date"
            className="mt-1 mb-6 border-2 w-full h-10 px-2 outline-none focus:border-purple-400/60 py-2 cursor-pointer"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Select from date"
          />
        </div>

        {/* To Date Picker */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t("To Date")}
          </label>
          <input
            type="date"
            className="mt-1 mb-6 border-2 w-full h-10 px-2 outline-none focus:border-purple-400/60 py-2 cursor-pointer"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Select to date"
            max={new Date().toISOString().split("T")[0]}
          />
        </div>

        {/* Location select component */}

        {/* Request type select component */}
        <RequestTypeSelect setOvertimeType={setOvertimeType} />

        {/* Department select component */}
        <DepartmentSelect
          setSelectedDepartment={setSelectedDepartment}
          departments={departments}
          status={isLoadingDepartments ? "loading" : "success"}
        />
        <LocationSelect
          setSelectedLocation={setSelectedLocation}
          companyLocations={locations}
          status={isLoadingLocations ? "loading" : "success"}
        />
      </div>
    </div>
  );
}

export default SelectBoxOvertimeApprovals;
