import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBqMjhai6UmXTr7FOduAr2IB1lVyYPwDMw",
  authDomain: "fcm-notification-project-63a57.firebaseapp.com",
  projectId: "fcm-notification-project-63a57",
  storageBucket: "fcm-notification-project-63a57.firebasestorage.app",
  messagingSenderId: "1034969872028",
  appId: "1:1034969872028:web:4ca92f58004cea7de31f59",
  measurementId: "G-E5ZJXC978K",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
//console.log(messaging);

// Register the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}



// Request FCM token
const requestFcmToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: 'BEvh7wMosTH3aI1cQg5RrtIOhWPi6Y-EhzzFzpPSkX9UYYfBzYjWVEru1OWzxF6xyi9h8DKINPoKZuo-kMR7zvo',
    });
    console.log('FCM Token:', token);
    return token;
  } catch (error) {
    console.error('Error getting FCM token:', error);
  }
};





const onNotificationReceived = (setNotifications) => {
  onMessage(messaging, (payload) => {
    console.log('Foreground notification received:', payload);
    if (payload.notification) {
      // Update the notifications list
      setNotifications((prevNotifications) => {
        const updatedNotifications = [...prevNotifications, payload.notification];
        console.log('Updated Notifications:', updatedNotifications);
        return updatedNotifications;
      });
    }
  });
}





export { messaging , requestFcmToken, onNotificationReceived };
