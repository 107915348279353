import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../context/ThemeContext";
import {
  MoonIcon,
  SunIcon,
  BellIcon,
  MenuIcon,
  OutlinePersonIcon,
  OutlineCogIcon,
  OutlineLogoutIcon,
} from "../../../assets/asset";
import {
  Avatar,
  Badge,
  Dropdown,
  DropdownItem,
  WindmillContext,
} from "@windmill/react-ui";

import { messaging, onNotificationReceived } from "../../../firebase";
import { onMessage } from "firebase/messaging";

function HeaderContain(props) {
  const { mode, toggleMode } = useContext(WindmillContext);
  const { toggleSidebar } = useContext(ThemeContext);

  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  // Load notifications from localStorage on component mount
  useEffect(() => {
    const storedNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];
    setNotifications(storedNotifications);
  }, []);

  useEffect(() => {
    const storedNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];
    setNotifications(storedNotifications);

    // Optionally clear old notifications from local storage
    localStorage.removeItem("notifications");
  }, []);

  // Firebase onMessage listener for receiving foreground notifications
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Foreground notification received:", payload);

      if (payload.notification) {
        setNotifications((prevNotifications) => {
          const validNotifications = prevNotifications.filter(Boolean);
          const updatedNotifications = [
            ...validNotifications,
            payload.notification,
          ];
          localStorage.setItem(
            "notifications",
            JSON.stringify(updatedNotifications)
          );
          return updatedNotifications;
        });
      }
    });

    return () => unsubscribe();
  }, []);

  // Clear all notifications
  const handleClearAllNotifications = () => {
    setNotifications([]);
    localStorage.removeItem("notifications");
  };

  // Handle opening and closing of notifications dropdown
  function handleNotificationsClick() {
    setIsNotificationsMenuOpen(!isNotificationsMenuOpen);
    setIsProfileMenuOpen(false);
  }

  function handleProfileClick() {
    setIsProfileMenuOpen(!isProfileMenuOpen);
    setIsNotificationsMenuOpen(false);
  }

  // Request FCM token on component mount
  useEffect(() => {
    onNotificationReceived(setNotifications);
  }, []);

  return (
    <header className="z-40 py-4 bg-white shadow-bottom dark:bg-gray-800">
      <div className="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
        {/* Mobile hamburger */}
        <button
          className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-purple"
          onClick={toggleSidebar}
          aria-label="Menu"
        >
          <MenuIcon className="w-6 h-6" aria-hidden="true" />
        </button>

        {/* Search input */}
        <div className="flex justify-center flex-1 lg:mr-32">
          <div className="relative w-full max-w-xl mr-6 focus-within:text-purple-500">
            {/* Search input code can go here */}
          </div>
        </div>

        <ul className="flex items-center flex-shrink-0 space-x-6">
          <li className="relative">
            <button
              className="relative align-middle rounded-md focus:outline-none focus:shadow-outline-purple"
              onClick={handleNotificationsClick}
              aria-label="Notifications"
              aria-haspopup="true"
            >
              <BellIcon className="w-5 h-5" aria-hidden="true" />
              {notifications.length > 0 && (
                <span
                  aria-hidden="true"
                  className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"
                />
              )}
            </button>

            <Dropdown
              align="right"
              isOpen={isNotificationsMenuOpen}
              onClose={() => {
                //setIsNotificationsMenuOpen(false)
              }}
            >
              {notifications.length === 0 ? (
                <DropdownItem>
                  <span>No notifications</span>
                </DropdownItem>
              ) : (
                notifications.map((notification, index) => (
                  <DropdownItem key={index} className="flex flex-col">
                    <span className="font-semibold text-gray-800 dark:text-gray-200">
                      {notification?.title || "No Title"}
                    </span>
                    <span className="text-sm text-gray-600 dark:text-gray-400">
                      {notification?.body || "No Content"}
                    </span>
                  </DropdownItem>
                ))
              )}
              {notifications.length > 0 && (
                <div className="p-2 border-t">
                  <button
                    className="text-sm text-red-500 hover:underline"
                    onClick={handleClearAllNotifications}
                  >
                    Clear All
                  </button>
                </div>
              )}
            </Dropdown>
          </li>

          {/* Profile menu */}
          <li className="relative">
            <button
              className="rounded-full focus:shadow-outline-purple focus:outline-none"
              onClick={handleProfileClick}
              aria-label="Account"
              aria-haspopup="true"
            >
              <Avatar
                className="align-middle"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"
                alt=""
                aria-hidden="true"
              />
            </button>
            <Dropdown
              align="right"
              isOpen={isProfileMenuOpen}
              onClose={() => {
                //setIsProfileMenuOpen(false)
              }}
            >
              <DropdownItem tag="a" href="/app/dashboard">
                <OutlinePersonIcon
                  className="w-4 h-4 mr-3"
                  aria-hidden="true"
                />
                <span>Profile</span>
              </DropdownItem>
              <DropdownItem tag="a" href="#" onClick={props.handleSettingClick}>
                <OutlineCogIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem onClick={props.onClick}>
                <OutlineLogoutIcon
                  className="w-4 h-4 mr-3"
                  aria-hidden="true"
                />
                <span>Log out</span>
              </DropdownItem>
            </Dropdown>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default HeaderContain;
