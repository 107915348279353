import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Windmill } from "@windmill/react-ui";
import { ThemedSuspense } from "@futurionic/ui";
import { ThemeProvider } from "./components/main/context/ThemeContext";
import "./i18nextConf";
import "./assets/css/tailwind.css";
import App from "./App";
import MyTheme from "./assets/theme/MyTheme";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: (query) => {
        const error = query.state.error;
        // Disable refetching if the last error was 405
        if (error && error.response && error.response.status === 405) {
          return false;
        }
        return true; // Default behavior for other cases
      },
      retry: (failureCount, error) => {
        // Disable retries for 405 errors
        if (error.response && error.response.status === 405) {
          return false;
        }
        return failureCount < 3; // Default retry for other errors
      },
      refetchInterval: 60000,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider>
          <Suspense fallback={<ThemedSuspense />}>
            <Windmill theme={MyTheme} usePreferences>
              <QueryClientProvider client={queryClient}>
                {/* <React.StrictMode> */}
                <App />
                <ReactQueryDevtools />
                {/* </React.StrictMode> */}
              </QueryClientProvider>
            </Windmill>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
