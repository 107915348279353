import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import platform from "platform";
import { logInApi } from "../api/User";
import FormikSignInFrom from "../forms/signIn";
import { logIn, setUser } from "../../../store/authenticationSlice";
import { captureCompany } from "../../../store/companySlice";
import { requestFcmToken } from "../../../firebase";

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getDeviceDetails = () => {
    // Use platform package to get device details
    const deviceType =
      platform.os.family === "iOS" || platform.os.family === "Android"
        ? "mobile"
        : "desktop";
    const deviceName = platform.name + " " + platform.version; 

   // console.log(deviceType, deviceName);
    return { deviceType, deviceName };
  };

  const handleSubmit = async (values) => {
    console.log("clicked Login");
    try {
      // Request the FCM token
      const fcmToken = await requestFcmToken();

      // Get device details using platform package
      const { deviceType, deviceName } = getDeviceDetails();

      // Prepare user data with additional info
      const userData = {
        ...values, 
        fcmToken,
        deviceType,
        deviceName,
      };
     // console.log(userData);
      const response = await logInApi(userData);
      console.log(response);
      localStorage.setItem("refresh-token", response?.data?.refreshToken);

      dispatch(logIn(response?.data?.token));
      dispatch(setUser(response?.data?.userData));
      dispatch(captureCompany(response?.data?.userData?.company));

      navigate("/app", { replace: true });
    } catch (err) {
      alert(err.response.data.message);
    }
  };

  console.log(process.env.REACT_APP_SERVER);

  return <FormikSignInFrom handleFormSubmit={handleSubmit} />;
}
export default SignIn;
