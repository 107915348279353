import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  Input,
  Button,
  TableFooter,
  Pagination,
} from "@windmill/react-ui";
import { useTranslation } from "react-i18next";
import { approveOvertimeRequest } from "../api/overtimeRequests";
import { CheckIcon } from "@heroicons/react/solid";
import OvertimeApprovalModal from "./OvertimeApprovalModal";
import Swal from "sweetalert2";
import Spinner from "../../../components/UI Components/Spinner";
import { SearchIcon } from "@heroicons/react/solid";
import TooltipComponent from "../../../components/UI Components/wrappers/Tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const OvertimeApprovalTable = ({
  approvalTaskData,
  refetchApprovalTasks,
  loading,
  setTermName,
  setEmployeeCode,
  approvalTaskCount,
  resultsPerPage,
  setPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taskToApprove, setTaskToApprove] = useState(null);
  const [reasons, setReasons] = useState("");
  const [overtimeData, setOvertimeData] = useState([]);
  const navigate = useNavigate();

  console.log(approvalTaskData);

  const openApprovePage = (task) => {
    if (!task) return;

    // Here we use navigate to go to the new page
    setTaskToApprove(task);

    // For example, navigating to `/approve-overtime` page and passing the task ID or task data
    navigate(`/app/approve-overtime/${task.id}`, { state: { task } });
  };

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectedRows(checked ? approvalTaskData.map((task) => task.id) : []);
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleApprovalAction = async (actionType) => {
    const tasksToProcess = taskToApprove
      ? [taskToApprove]
      : approvalTaskData.filter((task) => selectedRows.includes(task.id));

    if (!tasksToProcess.length) return;

    try {
      setIsLoading(true);

      for (const task of tasksToProcess) {
        const inputData = {
          tasks: [
            {
              taskId: task.id,
              employeeId: task.employeeDetailsMap?.employeeId || 1,
              userRoleId: task.employeeDetailsMap?.userRoleId || 1,
              requestTypeId: task.requestTypeId,
              companyId: task.companyId,
              action: actionType,
              reasons: actionType === "APPROVED" ? reasons : "",
              noteByEmployee: task.noteByEmployee,
              // For bulk action, keep existing overtime details
              overtimeDetails: taskToApprove
                ? task.id === taskToApprove.id
                  ? overtimeData.map((item) => ({
                      date: item.date,
                      overtimeMin: convertTimeToMinutes(item.overtimeHours),
                    }))
                  : task.requestOvertime.overtimeDetails
                : task.requestOvertime.overtimeDetails,
              waitingOrHaultingDetails: taskToApprove
                ? task.id === taskToApprove.id
                  ? overtimeData.map((item) => ({
                      date: item.date,
                      waitingOrHaultingMin: convertTimeToMinutes(
                        item.haltingHours
                      ),
                    }))
                  : task.requestOvertime.waitingOrHaultingDetails || []
                : task.requestOvertime.waitingOrHaultingDetails || [],
            },
          ],
        };

        await approveOvertimeRequest(
          task.employeeDetailsMap.userId,
          task.companyId,
          task.requestTypeId,
          inputData
        );
      }

      // Use SweetAlert to show a success message
      Swal.fire({
        title: "Success!",
        text: `Tasks ${actionType} successfully.`,
        icon: "success",
        confirmButtonText: "OK",
      });

      setSelectedRows([]);
      refetchApprovalTasks();
    } catch (error) {
      console.error("Error approving/rejecting tasks:", error);
      // Use SweetAlert to show an error message
      Swal.fire({
        title: "Error!",
        text: "There was an error processing your request. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* Actions */}
      <div className="flex mt-4 justify-between">
        {/* Search input */}
        <div className="relative w-full max-w-xl mr-6 focus-within:text-purple-500">
          <Input
            className="pl-3 border text-gray-700"
            placeholder={t("EMPLOYEE NAME")}
            aria-label="Search"
            onChange={(e) => setTermName(e.target.value)}
          />
          <div className="absolute inset-y-0 right-2 flex items-center pl-2">
            <SearchIcon className="w-4 h-4" aria-hidden="true" />
          </div>
        </div>
        <div className="relative w-full max-w-xl mr-6 focus-within:text-purple-500">
          <Input
            className="pl-3 border text-gray-700"
            placeholder={t("EMPLOYEE CODE")}
            aria-label="Search"
            onChange={(e) => setEmployeeCode(e.target.value)}
          />
          <div className="absolute inset-y-0 right-2 flex items-center pl-2">
            <SearchIcon className="w-4 h-4" aria-hidden="true" />
          </div>
        </div>
        <div className="flex  space-x-2 mb-4 mr-8">
          <Button
            onClick={() => handleApprovalAction("REJECTED")}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "REJECT"}
          </Button>
          <Button
            onClick={() => handleApprovalAction("APPROVED")}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "APPROVE"}
          </Button>
        </div>
      </div>

      {/* Table */}
      <TableContainer className="border-2 w-[260px] md:w-[650px] lg:w-[1000px] overflow-x-auto ">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Input
                  className="border-2 border-gray-800 rounded cursor-pointer"
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </TableCell>
              <TableCell className="text-xs">{t("EMPLOYEE_NAME")}</TableCell>
              <TableCell className="text-xs">{t("EMPLOYEE_CODE")}</TableCell>
              <TableCell className="text-xs">{t("LEGAL_ENTITY")}</TableCell>
              <TableCell className="text-xs">{t("BUSINESS_UNIT")}</TableCell>
              <TableCell className="text-xs">{t("DEPARTMENT")}</TableCell>
              <TableCell className="text-xs">{t("SUB_DEPARTMENT")}</TableCell>
              <TableCell className="text-xs">{t("LOCATION")}</TableCell>
              <TableCell className="text-xs">{t("OVERTIME_DATE")}</TableCell>
              <TableCell className="text-xs">{t("REQUEST_TYPE")}</TableCell>
              <TableCell className="text-xs">{t("TOTAL_OVERTIME")}</TableCell>
              <TableCell className="text-xs">{t("NOTE")}</TableCell>
              <TableCell className="text-xs">{t("STATUS")}</TableCell>
              <TableCell className="text-xs">{t("REQUESTED_BY")}</TableCell>
              <TableCell className="text-xs">{t("LAST_ACTION")}</TableCell>
              <TableCell className="text-xs">{t("ACTION")}</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={14} rowSpan={100} className="text-center">
                  {/* <Spinner /> */}
                  Loading...
                </TableCell>
              </TableRow>
            ) : approvalTaskData.length > 0 ? (
              approvalTaskData.map((task) => {
                const totalMinutes =
                  task.requestOvertime?.overtimeDetails.reduce(
                    (acc, detail) => acc + detail.overtimeMin,
                    0
                  ) || 0;
                const totalOvertimeHours = convertMinutesToTime(totalMinutes);

                return (
                  <TableRow key={task.id}>
                    <TableCell>
                      <Input
                        className="border-2 border-gray-800 rounded cursor-pointer text-xs"
                        type="checkbox"
                        checked={selectedRows.includes(task.id)}
                        onChange={() => handleCheckboxChange(task.id)}
                      />
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.employeeDetailsMap?.employeeName || ""}
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.employeeDetailsMap?.employeeCode || "N/A"}
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.entityDetail?.name || "N/A"}
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.employeeDetailsMap?.businessUnit || "N/A"}
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.employeeDetailsMap?.department || "N/A"}
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.employeeDetailsMap?.subDepartment || "N/A"}
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.entityLocations?.name || "N/A"}
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.requestOvertime.overtimeDetails.length > 0 && (
                        <div>
                          {task.requestOvertime.overtimeDetails.length === 1 ? (
                            <div>
                              {new Date(
                                task.requestOvertime.overtimeDetails[0].date
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            </div>
                          ) : (
                            // If there are multiple dates, display From - To format with year at the end if it's the same
                            (() => {
                              const startDate = new Date(
                                task.requestOvertime.overtimeDetails[0].date
                              );
                              const endDate = new Date(
                                task.requestOvertime.overtimeDetails[
                                  task.requestOvertime.overtimeDetails.length -
                                    1
                                ].date
                              );
                              const startFormatted =
                                startDate.toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "short",
                                });

                              const endFormatted = endDate.toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "short",
                                }
                              );
                              const yearFormatted = startDate.getFullYear();
                              return (
                                <div>
                                  {startDate.getFullYear() ===
                                  endDate.getFullYear()
                                    ? // If the year is the same, show dates without repeating the year
                                      `${startFormatted} - ${endFormatted}, ${yearFormatted}`
                                    : // If the year is different, show full dates
                                      `${startFormatted}, ${startDate.getFullYear()} - ${endFormatted}, ${endDate.getFullYear()}`}
                                </div>
                              );
                            })()
                          )}
                        </div>
                      )}
                    </TableCell>

                    <TableCell className="text-xs">
                      {task.requestOvertime.type || "N/A"}
                    </TableCell>
                    <TableCell className="flex items-center text-xs space-x-5">
                      {/* Show total overtime hours */}
                      {totalOvertimeHours}

                      {/* Tooltip with full overtime details */}
                      <TooltipComponent
                        tooltipText={
                          task.requestOvertime.overtimeDetails
                            .map(
                              (detail) =>
                                `<div style="margin-bottom: 4px; font-size: 1em;">
             <strong>Date:</strong> ${new Date(detail.date).toLocaleDateString(
               "en-GB",
               {
                 day: "2-digit",
                 month: "short",
                 year: "numeric",
               }
             )}<br />
             <strong>Overtime:</strong> ${convertMinutesToTime(
               detail.overtimeMin
             )}
           </div>`
                            )
                            .join("") // Join all overtime details to display them in the tooltip
                        }
                        tooltipId={`overtime-tooltip-${task.id}`} // Unique tooltip ID for each task
                      >
                        {/* Icon to trigger the tooltip */}
                        {/* <span className=" text-blue-900 cursor-pointer"> */}
                        <AiOutlineInfoCircle className="ml-3 w-4 h-4 text-blue-400" />
                        {/* </span> */}
                      </TooltipComponent>
                    </TableCell>

                    <TableCell className="text-xs">
                      <TooltipComponent
                        tooltipText={
                          task.requestOvertime?.noteByEmployee || "N/A"
                        }
                        tooltipId={`note-tooltip-${task.id}`} // Unique tooltip ID for each task
                      >
                        <div className="text-blue-600 underline cursor-pointer">
                          View Note
                        </div>
                      </TooltipComponent>
                    </TableCell>

                    <TableCell className="text-xs">
                      {task.status || "N/A"}
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.employeeDetailsMap?.employeeName || ""}
                    </TableCell>
                    <TableCell className="text-xs">
                      {task.currentApproverName || "N/A"}
                    </TableCell>

                    {/* Action Column */}
                    <TableCell>
                      <div className="flex">
                        <CheckIcon
                          className="w-5 h-5 text-green-500 cursor-pointer"
                          onClick={() => openApprovePage(task)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={14} className="text-center">
                  {t("No approval tasks found.")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={approvalTaskCount}
            resultsPerPage={resultsPerPage}
            currentPage={currentPage}
            onChange={setPage}
            label="Employee Pagination"
          />
        </TableFooter>
      </TableContainer>
    </div>
  );
};

export default OvertimeApprovalTable;

// Helper function to convert minutes to time format
const convertMinutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
};

// Helper function to convert time format (HH:MM) back to minutes
const convertTimeToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};
