import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Input, Label, Button, Select as UiSelect } from "@windmill/react-ui";
import SearchSelect from "react-select";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { searchEmployees } from "../../../employee/api/employee";
import { isEmpty, isFunction } from "lodash";
import { LOCATION_INITIAL_DATA } from "./constant";
import { locationSchema } from "../schema";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { listCities, listStates } from "../../../../api/common";

const LocationForm = ({
  handleFormSubmit,
  initialValue,
  buttons,
  isDeptHeads = true,
}) => {
  const { t } = useTranslation();
  const [employeeHeads, setEmployeeHeads] = useState([]);
  const { companyId } = useSelector((state) => state.companyData);

  const { data: dataEmployee, isLoading } = useQuery({
    queryKey: ["employeeList", companyId],
    queryFn: () => searchEmployees({ companyId }),
    keepPreviousData: true,
  });

  const options = useMemo(() => {
    if (dataEmployee) {
      return dataEmployee?.rows?.map((employee) => ({
        value: employee.id,
        label: employee.employee.fullName.trim(),
      }));
    }
    return [];
  }, [dataEmployee]);
  const isViewMode = !handleFormSubmit;
  const initialValues = {
    ...LOCATION_INITIAL_DATA,
    ...initialValue,
  };

  const [cities, setCities] = useState([]);
  const [state, setState] = useState([]);
  const countryId = 1;
  const [stopSelection, setStopSelection] = useState(true);

  console.log(isViewMode, stopSelection);
  useEffect(() => {
    listStates(countryId).then((data) => setState(data));
  }, [countryId]);

  const handleOnChange = async (event) => {
    setStopSelection(true);
    const city = await listCities(event.target.value);
    setCities(city);
    setStopSelection(false);
  };
  useEffect(() => {
    if (initialValue && !isEmpty(initialValue[`locationHead`])) {
      let employee = [];
      initialValue[`locationHead`].forEach((element) => {
        employee.push(options.find((employee) => employee.value === element));
      });
      setEmployeeHeads(employee);
    }
    if (initialValue && initialValue.stateId) {
      setStopSelection(true);
      listCities(initialValue.stateId).then((city) => {
        setCities(city);
        setStopSelection(false);
      });
    }
  }, [initialValue, options]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={locationSchema}
      onSubmit={handleFormSubmit || (() => {})} // no-op function for view mode
      enableReinitialize={true}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
        <form
          autoComplete="off"
          className="space-y-4 bg-white shadow-xl pb-6"
          onSubmit={isViewMode ? (e) => e.preventDefault() : handleSubmit}
        >
          <div className="mb-10 ml-5">
            <Label className="w-full flex justify-between items-center pb-4">
              <span>{t("Location Name")}*</span>
              <div className="w-2/3 md:w-4/5">
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter Location Name`}
                  name={`name`}
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name={`name`}
                  component="div"
                  className="text-red-600"
                />
              </div>
            </Label>
            <Label className="w-full flex justify-between items-center pb-4">
              <span>{t("Location Code")}*</span>
              <div className="w-2/3 md:w-4/5">
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter Location Code`}
                  name={"locationCode"}
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name={"locationCode"}
                  component="div"
                  className="text-red-600"
                />
              </div>
            </Label>
            <Label className="w-full flex justify-between items-center pb-4">
              <span>{t("Description")}*</span>
              <div className="w-2/3 md:w-4/5">
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter Location Description`}
                  name="description"
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-600"
                />
              </div>
            </Label>
            {isDeptHeads && (
              <Label className="w-full flex justify-between items-center pb-4">
                <span>{t("Location Head(s)")}*</span>
                <div className="w-2/3 md:w-4/5">
                  <SearchSelect
                    className="mt-1 mb-6 border"
                    name={`locationHead`}
                    placeholder={`Select Location Heads`}
                    isMulti
                    value={employeeHeads}
                    onChange={(selectedOptions) => {
                      if (!isViewMode) {
                        setEmployeeHeads(selectedOptions);
                        const selectedIds = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];
                        setFieldValue(`locationHead`, selectedIds);
                      }
                    }}
                    options={options}
                    isDisabled={isViewMode}
                  />
                </div>
              </Label>
            )}

            <Label className="w-full flex justify-between items-center pb-4">
              <span>{t("ADDRESS_LINE_1")}*</span>

              <div className="w-2/3 md:w-4/5">
                <Field
                  as={Input}
                  className="border mt-1 mb-3  block  "
                  name="addressLine1"
                  type="text"
                  placeholder="address line 1 "
                  disabled={isViewMode}
                />
                <ErrorMessage name="addressLine1" />
              </div>
            </Label>

            <Label className="w-full flex justify-between items-center pb-4">
              <span>{t("ADDRESS_LINE_2")}*</span>

              <div className="w-2/3 md:w-4/5">
                <Field
                  as={Input}
                  className="border mt-1 mb-3  block  "
                  name="addressLine2"
                  type="text"
                  placeholder="address line 2"
                  disabled={isViewMode}
                />
                <ErrorMessage name="addressLine2" />
              </div>
            </Label>
            <Label className=" w-full flex justify-between items-center pb-4">
              <span>{t("Country")}*</span>

              <div className="w-2/3 md:w-4/5">
                <Field
                  as={UiSelect}
                  value={values?.countryId}
                  className="border
                   mt-1 mb-3 w-full py-2 block "
                  name="countryId"
                  disabled={isViewMode}
                >
                  <option value={0}>Select Country</option>
                  <option key={1} value={1}>
                    India
                  </option>
                </Field>
                <ErrorMessage
                  name="countryId"
                  component="div"
                  className="text-red-600"
                />
              </div>
            </Label>

            <Label className="w-full flex justify-between items-center pb-4">
              <span>{t("STATE")}*</span>

              <div className="w-2/3 md:w-4/5">
                <UiSelect
                  name="stateId"
                  value={values?.stateId}
                  onChange={(event) => {
                    setFieldValue("stateId", event.target.value);
                    handleOnChange(event);
                  }}
                  disabled={isViewMode}
                  className="border py-2 mt-1 mb-3 w-full block "
                >
                  <option>Select State</option>
                  {!isEmpty(state) &&
                    state.map(({ id, name }) => {
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                </UiSelect>
                <ErrorMessage name="stateId" />
              </div>
            </Label>

            <Label className="w-full flex justify-between items-center pb-4">
              <span>{t("CITY")}*</span>

              <div className="w-2/3 md:w-4/5">
                <Field
                  as={UiSelect}
                  value={values?.cityId}
                  className="border
                   mt-1 mb-3 w-full py-2 block "
                  name="cityId"
                  disabled={stopSelection || isViewMode}
                >
                  <option>Select City</option>
                  {!isEmpty(cities) &&
                    cities.map(({ id, name }) => {
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                </Field>
                <ErrorMessage name="cityId" />
              </div>
            </Label>

            <Label className="w-full flex justify-between items-center pb-4">
              <span>{t("PINCODE")}*</span>

              <div className="w-2/3 md:w-4/5">
                <Field
                  as={Input}
                  className="border mt-1 mb-3  block  "
                  name="pincode"
                  type="text"
                  placeholder="pincode"
                  disabled={isViewMode}
                />
                <ErrorMessage name="pincode" />
              </div>
            </Label>
          </div>
          {!buttons && (
            <div className="max-w-full flex justify-center  items-center space-x-8 ">
              <Link to="/app/organisation/location">
                <Button type="button" className="mr-4" layout="outline">
                  CANCEL
                </Button>
              </Link>

              {!isViewMode && <Button type="submit">Save</Button>}
            </div>
          )}
          {isFunction(buttons) && buttons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default LocationForm;
