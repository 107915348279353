 import { createSlice } from "@reduxjs/toolkit";


 const penalizationPolicyFormSlice = createSlice({

        name : 'penalizationPolicyForm',

        initialState : {
            violationForPenalizationId : null,
            penalizationPolicyId : null,
            methodsForPenalization: {},
        },

        reducers : {
            setPenalizationPolicyId: (state , action) => {
                state.penalizationPolicyId = action.payload
                console.log("penalizationPolicyId", state.penalizationPolicyId)
            },

            setViolationForPenalizationId : (state , action) => {
                state.violationForPenalizationId = action.payload
                console.log("setViolationForPenalizationId", state.violationForPenalizationId)
            },
            setMethodsForPenalization: (state, action) => {
                state.methodsForPenalization = action.payload;
                console.log("setMethodsForPenalization", state.methodsForPenalization);
              },
        }
 });

 export const { setPenalizationPolicyId, setViolationForPenalizationId ,setMethodsForPenalization} = penalizationPolicyFormSlice.actions;

export default penalizationPolicyFormSlice.reducer;