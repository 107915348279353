import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const TooltipComponent = ({ children, tooltipText, tooltipId }) => {
  return (
    <div
      data-tooltip-id={tooltipId}
      data-tooltip-html={tooltipText}
      className=" inline-block cursor-pointer"
    >
      {children}
      <ReactTooltip
        id={tooltipId}
        place="top"
        effect="solid"
        html={true}
        className="bg-white text-black p-2 rounded-md shadow-lg" 
        style={{
          whiteSpace: 'normal', // Allow text to break to the next line
          wordWrap: 'break-word', // Break long words onto the next line
          maxWidth: '200px', // Limit the width of the tooltip
        }}

      />
    </div>
  );
};

export default TooltipComponent;
